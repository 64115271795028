<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-form inline>
          <label class="mr-sm-2" for="inline-form-custom-select-pref"
            >Parent Id</label
          >
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="selectedParentId"
            :options="parentIds"
            @change="onParentIdChange()"
          >
            <template v-slot:first>
              <option :value="null">Root</option>
            </template>
          </b-form-select>
        </b-form>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            perPage: 20,
            perPageDropdown: [20],
            dropdownAllowAll: false
          }"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div>
                <router-link
                  :to="{
                    name: 'postForm',
                    params: { id: props.formattedRow.id }
                  }"
                >
                  <b-button variant="outline-primary">Edit</b-button>
                </router-link>
              </div>
            </span>
            <span v-else>{{ props.formattedRow[props.column.field] }}</span>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import moment from "moment";

export default {
  data() {
    return {
      isLoading: true,
      columns: [
        {
          label: "ID",
          field: "id",
          sortable: false
        },
        {
          label: "container",
          field: "containerId",
          sortable: false
        },
        {
          label: "Title",
          field: "title",
          sortable: false
        },
        {
          label: "Locked",
          field: "locked",
          sortable: false
        },
        {
          label: "Creation Date",
          field: "createdAt",
          sortable: false
        },
        {
          label: "Update Date",
          field: "updatedAt",
          sortable: false
        },
        {
          label: "Actions",
          field: "actions",
          sortable: false
        }
      ],
      rows: [],
      totalRecords: 0,
      parentIds: [],
      selectedParentId: null,
      serverParams: {
        attributes: "id,containerId,parentId,title,locked,createdAt,updatedAt",
        page: 1,
        perPage: 20
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Posts"
      }
    ]);
    this.loadItems();
    this.loadParentIds();
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onParentIdChange() {
      if (this.selectedParentId) {
        this.updateParams({ parentId: this.selectedParentId, page: 1 });
      } else {
        delete this.serverParams.parentId;
        this.updateParams({ page: 1 });
      }
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
      this.isLoading = true;
      return new Promise(resolve => {
        ApiService.get("posts", this.serverParams)
          .then(({ data }) => {
            let items = data.response.items;
            items.map(item => {
              item.createdAt = moment(item.createdAt).format("MM/DD/YYYY LT");
              item.updatedAt = moment(item.updatedAt).format("MM/DD/YYYY LT");
            });

            this.rows = items;
            this.totalRecords = data.response.itemCount;
            this.isLoading = false;
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    },
    loadParentIds() {
      return new Promise(resolve => {
        ApiService.get(
          "posts?type=Posts&parentId=0&containerId=!faq&attributes=id,title"
        )
          .then(({ data }) => {
            const items = data.response.items;

            items.forEach(item => {
              this.parentIds.push({ value: item.id, text: item.title });
            });
            resolve();
          })
          .catch(e => {
            console.log(e.message);
          });
      });
    }
  }
};
</script>
